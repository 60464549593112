<script lang="ts" setup>
const localePath = useLocalePath()
</script>
<template>
    <div class="px-4 mx-auto max-w-screen-lg text-center py-20">
        <div class="flex flex-col md:flex-row gap-8 bg-[#F2E8FE] mx-auto rounded-2xl items-center py-12 px-10">
       
        <div class="pb-6 pt-6 md:pt-0 max-w-lg mx-auto">
            <p class="text-[#7E1EF8]">{{$t('home.privacy.title')}}</p>
            <h4 class="text-3xl font-bold font-owners text-[#290B50]">{{$t('home.privacy.headline')}}</h4>
            <p class="mt-4 mb-10">{{$t('home.privacy.body')}}</p>

						<NuxtLink :to="localePath('/privacy')" class="btn-primary leading-5 py-3 md:py-4 text-lg">
							{{$t('home.privacy.action')}}
						</NuxtLink>
        </div> 
        
    </div>
    </div>
</template>